import {useState} from "react";
import { countries } from "../../global/countriesAndSources";
import { addNewUser } from "../../HTTP/admin";

export default function AddUser() {
    const localUser = JSON.parse(localStorage.getItem("user"))
    if (!localUser.admin) {
        window.location.href = '/'
    }
    const [formData, setFormData] = useState({
        lastname: '',
        firstname: '',
        email: '',
        password: '',
        country: '',
        // admin: false
      });

      const [passwordIsValid, setPasswordIsValid] = useState(false);

      const validatePassword = (password) => {
        const regex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&µ£\/\\~|\-])[\wÀ-ÖØ-öø-ÿ@$!%*#?&µ£\/\\~|\-]{8,100}$/;
        return regex.test(password);
      };
    
    
      console.log(formData)
    
      const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData(prevData => ({
          ...prevData,
          [name]: type === 'checkbox' ? checked : value
        }));

        if (name === 'password') {
          setPasswordIsValid(validatePassword(value));
        }
      };
    
      const handleSubmit = async (e) => {
        e.preventDefault();
        const request = {
          firstname: formData.firstname,
          lastname: formData.lastname,
          email : formData.email,
          password : formData.password,
          country: formData.country,
        //   admin: formData.admin
        }
        try {
          const res = await addNewUser(request)
          console.log(res)
          alert("Utilisateur ajouté");
          window.location.href = "/admin/users";
        } catch (err) {
          console.log(err)
          alert("Une erreur est survenue : vérifier le mail ou le mot de passe");
        }
      };
    
      return (
        <div className="max-w-md mx-auto mt-8 p-6 bg-white rounded-lg shadow-md">
          <h2 className="text-2xl font-bold mb-6 text-center">Formulaire Utilisateur</h2>
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label htmlFor="lastname" className="block text-gray-700 text-sm font-bold mb-2">
                Nom
              </label>
              <input
                type="text"
                id="lastname"
                name="lastname"
                value={formData.lastname}
                onChange={handleChange}
                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              />
            </div>
            <div className="mb-4">
              <label htmlFor="firstname" className="block text-gray-700 text-sm font-bold mb-2">
                Prénom
              </label>
              <input
                type="text"
                id="firstname"
                name="firstname"
                value={formData.firstname}
                onChange={handleChange}
                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              />
            </div>
            <div className="mb-4">
              <label htmlFor="email" className="block text-gray-700 text-sm font-bold mb-2">
                Email
              </label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              />
            </div>
            <div className="mb-4">
              <label htmlFor="password" className={`block text-gray-700 text-sm font-bold mb-2`}>
                Mot de passe
              </label>
              <input
                type="password"
                id="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                className={`w-full px-3 py-2 border ${formData.password && (passwordIsValid ? 'border-green-500' : 'border-red-500')} rounded-md shadow-sm focus:outline-none`}
              />
              {formData.password && !passwordIsValid && (
                <p className="mt-2 text-sm text-red-600">
                  Le mot de passe doit contenir au moins 8 caractères, incluant une lettre, un chiffre et un caractère spécial.
                </p>
              )}
            </div>
            <div className="mb-4">
              <label htmlFor="country" className="block text-gray-700 text-sm font-bold mb-2">
                Pays
              </label>
              <select onChange={handleChange} className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500" name="country">
                  {
                    countries.map((c, i) => <option value={c.code} key={i}>{c.name}</option>)
                  }
                </select>
            </div>
            {/* <div className="mb-6">
              <label className="flex items-center">
                <input
                  type="checkbox"
                  name="admin"
                  checked={formData.admin}
                  onChange={handleChange}
                  className="form-checkbox h-5 w-5 text-blue-600"
                />
                <span className="ml-2 text-gray-700">Utilisateur administrateur</span>
              </label>
            </div> */}
            <div className="flex items-center justify-end">
              <button
                type="submit"
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              >
                Soumettre
              </button>
            </div>
          </form>
        </div>
      );
}