import React, { useEffect } from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4maps from '@amcharts/amcharts4/maps';
import am4geodata_worldLow from '@amcharts/amcharts4-geodata/worldMoroccoLow';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import { countries } from '../../global/countriesAndSources';

const Map = (props) => {
  const setSelectedCountry = props.setSelectedCountry
	const selectedCountry = props.selectedCountry
  const mapDisplay = props.displayMap
  const setDisplayMap = props.setDisplayMap
  useEffect(() => {
    if (document.getElementById('globeDiv')) {
      if (!mapDisplay) {
        document.getElementById("globeDiv").style["display"] = 'none'
      } else {
        document.getElementById("globeDiv").style["display"] = ''
      }
    }
    am4core.useTheme(am4themes_animated);

    let chart = am4core.create("globeDiv", am4maps.MapChart);
    chart.geodata = am4geodata_worldLow;
    chart.projection = new am4maps.projections.Orthographic();
    chart.panBehavior = "rotateLongLat";
    chart.deltaLatitude = -20;
    chart.padding(20,20,20,20);

    let polygonSeries = chart.series.push(new am4maps.MapPolygonSeries());
    polygonSeries.useGeodata = true;

    let polygonTemplate = polygonSeries.mapPolygons.template;
    polygonTemplate.tooltipText = "{name}";
    polygonTemplate.fill = am4core.color("#008eb5");
    polygonTemplate.stroke = am4core.color("#cae1e1");
    polygonTemplate.strokeWidth = 0.75; 
    // Gérer le clic sur un pays
    polygonTemplate.events.on("hit", function(ev) {
        setDisplayMap(false)
        const countryId = ev.target.dataItem.dataContext.id.toLowerCase();
        const country = countries.find((c) => c.code === countryId)
        if (country !== undefined) {

          setSelectedCountry(country)
          Array.from(document.getElementsByClassName('divli')).find((c) => c.innerText === country.name).scrollIntoView({ behavior: 'smooth', block: 'start' })
        }
      });

    let hs = polygonTemplate.states.create("hover");
    hs.properties.fill = am4core.color("#9cfdff");

     // Ajouter la rotation automatique
     
      let animation = chart.animate({ property: "deltaLongitude",  to: 10000 }, 1000000, am4core.ease.linear);
      animation.events.on("animationended", () => animation.restart());
      
      // Arrêter la rotation lorsque l'utilisateur commence à faire glisser le globe
      chart.seriesContainer.events.on("down", function() {
        animation.stop();
      });

        // if (document.getElementById('globeDiv')) {
        //   document.getElementById("globeDiv").style["display"] = 'none'
        // }
    
        if (!mapDisplay) {
          chart.hidden = true
        } else {
          chart.hidden = false
        }

  
      // Optionnel: Redémarrer la rotation après que l'utilisateur ait fini de faire glisser
    //   chart.seriesContainer.events.on("up", function() {
    //     setTimeout(() => {
    //         animation.start();
    //       }, 5000);
    //   });
      
      return () => {
        chart.dispose();
      };
    }, [mapDisplay]);
    // See if country and query are set as url params.
    const queryCountry = new URLSearchParams(window.location.search).get('country') 
    if(queryCountry == undefined){
        return <div id="globeDiv" style={{ width: "100%", height: "60vh" }}></div>;
    }
    
};

export default Map;
