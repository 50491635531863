import {useState, useEffect} from "react";
import { useParams } from "react-router-dom";
import { addFavorite, createReview } from "../../HTTP/articles";
import { FormatBoldRounded } from "@mui/icons-material";

export default function AddFavorite() {
    const localUser = JSON.parse(localStorage.getItem("user"))
    if (!localUser.admin) {
        window.location.href = '/'
    }
    
  const [formData, setFormData] = useState({
    id: 0,
    user_id: localUser.id,
    title: 'test',
    link: 'test',
    image : 'test',
    country: 'test',
    publication_date: new Date().toISOString().replace("T", " ").slice(0, 19),
    description: 'test'
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const request = {
        title: formData.title,
        link: formData.link,
        image: formData.image,
        country: formData.country,
        publication_date: formData.publication_date,
        description: formData.description,
    }
    try {
      await addFavorite(request);
      alert("Favori ajouté avec succès");
      window.location.href = "/admin/favorites";
    } catch (err) {
      alert("Problème lors de l'enregistrement de l'article");
    }
  };

  return (
    <div className="max-w-md mx-auto mt-8 p-6 bg-white rounded-lg shadow-md">
      <h2 className="text-2xl font-bold mb-6 text-center">Formulaire Revue</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label htmlFor="title" className="block text-gray-700 text-sm font-bold mb-2">
            Thème
          </label>
          <input
            type="text"
            id="title"
            name="title"
            value={formData.title}
            onChange={handleChange}
            className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          />
        </div>
        <div className="mb-4">
          <label htmlFor="description" className="block text-gray-700 text-sm font-bold mb-2">
            Présentation
          </label>
          <textarea
            id="description"
            name="description"
            value={formData.description}
            onChange={handleChange}
            rows="4"
            className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          ></textarea>
        </div>
        <div className="flex items-center justify-end">
          <button
            type="submit"
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            Enregistrer
          </button>
        </div>
      </form>
    </div>
  );
}