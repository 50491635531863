import {useState, useEffect} from "react";
import { useParams } from "react-router-dom";
import { createReview } from "../../HTTP/articles";
import { FormatBoldRounded } from "@mui/icons-material";

export default function AddReview() {
    const localUser = JSON.parse(localStorage.getItem("user"))
    if (!localUser.admin) {
        window.location.href = '/'
    }
    
  const { id } = useParams();
  const [formData, setFormData] = useState({
    id: 0,
    theme: '',
    presentation: '',
    sharefriends: false
  });

  console.log(formData.articles)

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const request = {
        id: formData.id,
        theme: formData.theme,
        presentation: formData.presentation,
        sharefriends: formData.sharefriends
    }
    try {
      await createReview(formData.theme, formData.presentation, [], formData.sharefriends);
      alert("Revue ajoutée avec succès");
      window.location.href = "/admin/reviews";
    } catch (err) {
      alert("Problème lors de l'enregistrement de l'article");
    }
  };

  return (
    <div className="max-w-md mx-auto mt-8 p-6 bg-white rounded-lg shadow-md">
      <h2 className="text-2xl font-bold mb-6 text-center">Formulaire Revue</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label htmlFor="theme" className="block text-gray-700 text-sm font-bold mb-2">
            Thème
          </label>
          <input
            type="text"
            id="theme"
            name="theme"
            value={formData.theme}
            onChange={handleChange}
            className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          />
        </div>
        <div className="mb-4">
          <label htmlFor="presentation" className="block text-gray-700 text-sm font-bold mb-2">
            Présentation
          </label>
          <textarea
            id="presentation"
            name="presentation"
            value={formData.presentation}
            onChange={handleChange}
            rows="4"
            className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          ></textarea>
        </div>
        <div className="mb-6">
          <label className="flex items-center">
            <input
              type="checkbox"
              name="isPublic"
              checked={formData.isPublic}
              onChange={handleChange}
              className="form-checkbox h-5 w-5 text-blue-600"
            />
            <span className="ml-2 text-gray-700">Autoriser les amis à publier des articles dans cette revue</span>
          </label>
        </div>
        <div className="flex items-center justify-end">
          <button
            type="submit"
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            Enregistrer
          </button>
        </div>
      </form>
    </div>
  );
}