import { useEffect, useState } from "react"
import { getAllUsers, getNumberOfUsers, removeUser, getUsersByQuery, getNumberOfQueryUsers } from "../../HTTP/admin"
import { ArrowLeft } from "@mui/icons-material";

export default function UserAdmin() {
    const localUser = JSON.parse(localStorage.getItem("user"))
    if (!localUser.admin) {
        window.location.href = '/'
    }
    const url = new URL(window.location.href)
    if (url.searchParams.get('page') === null) {
        url.searchParams.set('page', 1);
        window.history.pushState({}, '', url);
    }

    const [users, setUsers] = useState([])
    const [searchTerm, setSearchTerm] = useState(url.searchParams.get('query') === null ? '' : url.searchParams.get('query'));
    const [currentPage, setCurrentPage] = useState(parseInt(url.searchParams.get('page')));
    const [usersPerPage] = useState(6);
    const [nbUsers, setNbUsers] = useState(0);

    useEffect(() => {
        const funNumberUsers = async () => {
            if (searchTerm !== '') {
                console.log("oui")
                const numberUsers = await getNumberOfQueryUsers(searchTerm)
                setNbUsers(numberUsers)
            } else {
                const numberUsers = await getNumberOfUsers()
                setNbUsers(numberUsers)
            }
        }
        funNumberUsers()
        const funSearchUsers = async () => {
            if (searchTerm !== '') {
                const searchUsers = await getUsersByQuery((currentPage - 1) * usersPerPage, currentPage * usersPerPage, searchTerm)
                setUsers(searchUsers)
            } else {
                const searchUsers = await getAllUsers((currentPage - 1) * usersPerPage, currentPage * usersPerPage)
                setUsers(searchUsers)
            }
        }
        funSearchUsers()
    }, [])
    
    console.log(nbUsers)
    const filteredUsers = users.filter(user =>
        user.lastname.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.firstname.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.email.toLowerCase().includes(searchTerm.toLowerCase())
    );

    // Change page
    const paginate = (pageNumber) => {
        window.location.href = "/admin/users?page=" + pageNumber + (url.searchParams.get("query") === null ? '' : "&query=" + url.searchParams.get("query"))
    }

    const handleQuery = (e) => {
        e.preventDefault()
        if (url.searchParams.get('query') === null) {
            url.searchParams.set('query', searchTerm);
            window.history.pushState({}, '', url);
        }
        window.location.href = "/admin/users?page=1&query=" + searchTerm
    }

    const handleEditUser = (event) => {
        window.location.href = "/admin/users/edit/" + event.target.value
    }

    const handleAddUser = () => {
        window.location.href = "/admin/users/add"
    }

    const handleBackToDashboard = () => {
        window.location.href = "/admin";
      };

    return (
        <>
        <div className="w-full px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between mb-6">
        <button
          onClick={handleBackToDashboard}
          className="bg-gray-200 hover:bg-gray-300 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center"
        >
          <ArrowLeft className="mr-2" size={20} />
          Retour
        </button>
        <h1 className="text-2xl font-bold text-center flex-grow">
          Admini'utilisateur
        </h1>
      </div>
        <form onSubmit={handleQuery} className="flex items-center mb-4">
            <div className="flex-grow mr-2">
                <input
                type="text"
                placeholder="Rechercher par nom ou email..."
                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                />
            </div>
            <button
                type="submit"
                className="bg-green-500 hover:bg-green-700 text-white font-bold mr-2 py-2 px-4 rounded-md whitespace-nowrap"
            >
                Recherche
            </button>
            <button
                type="button"
                className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded-md whitespace-nowrap"
                onClick={handleAddUser}
            >
                Ajouter
            </button>
            </form>
            <div className="w-full overflow-x-auto shadow-md rounded-lg">
                <table className="w-full table-auto">
                    <thead className="bg-gray-100">
                        <tr>
                            <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Nom</th>
                            <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Prénom</th>
                            <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Email</th>
                            <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
                        </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200">
                        {users.map((user, index) => (
                            <tr key={index} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                                <td className="px-4 py-4 break-words">
                                    <div className="max-w-xs lg:max-w-sm xl:max-w-md">{user.lastname}</div>
                                </td>
                                <td className="px-4 py-4 break-words">
                                    <div className="max-w-xs lg:max-w-sm xl:max-w-md">{user.firstname}</div>
                                </td>
                                <td className="px-4 py-4 break-words">
                                    <div className="max-w-xs lg:max-w-sm xl:max-w-md">{user.email}</div>
                                </td>
                                <td className="px-4 py-4 whitespace-nowrap">
                                    <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-2 mb-2 sm:mb-0" value={user.id} onClick={handleEditUser}>
                                        Éditer
                                    </button>
                                    <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded" onClick={() => {
                                        if (window.confirm("Êtes-vous sûr de vouloir supprimer cet élément ?")) {
                                            removeUser(user.id).then(() => alert("Utilisateur supprimé !")).catch((err) => alert("Erreur lors de la suppression de l'utilisateur : ", err))
                                            window.location.href = "/admin/users"
                                        } else {
                                            alert("Suppression annulée");
                                        }
                                    }}>
                                        Supprimer
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <Pagination
                usersPerPage={usersPerPage}
                totalUsers={nbUsers}
                paginate={paginate}
                currentPage={currentPage}
            />
        </div>
        </>
    )
}

function Pagination({ usersPerPage, totalUsers, paginate, currentPage }) {
    const pageNumbers = [];

    for (let i = 1; i <= Math.ceil(totalUsers / usersPerPage); i++) {
        pageNumbers.push(i);
    }

    return (
        <nav className="flex justify-center mt-4">
            <ul className="flex">
                {pageNumbers.map(number => (
                    <li key={number} className="mx-1">
                        <button
                            onClick={() => paginate(number)}
                            className={`px-3 py-2 rounded-md ${
                                currentPage === number
                                    ? 'bg-blue-500 text-white'
                                    : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
                            }`}
                        >
                            {number}
                        </button>
                    </li>
                ))}
            </ul>
        </nav>
    );
}