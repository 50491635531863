import { useEffect, useState, useMemo } from "react";
import { fetchQueryReviews, fetchReviewCount, fetchReviews, getNumberOfQueryReview, getUserById } from "../../HTTP/admin";
import { deleteReview } from "../../HTTP/articles";
import { getAllPublicUsers } from "../../HTTP/user";
import { ArrowLeft } from "@mui/icons-material";

export default function ReviewAdmin() {
    const localUser = JSON.parse(localStorage.getItem("user"))
    if (!localUser.admin) {
        window.location.href = '/'
    }

    const url = new URL(window.location.href)
    if (url.searchParams.get('page') === null) {
        url.searchParams.set('page', 1);
        window.history.pushState({}, '', url);
    }

    const [reviews, setReviews] = useState([])
    const [reviewCount, setReviewCount] = useState(0)
    const [searchTerm, setSearchTerm] = useState(url.searchParams.get('query') === null ? '' : url.searchParams.get('query'));
    const [currentPage, setCurrentPage] = useState(parseInt(url.searchParams.get('page')));
    const [usersPerPage] = useState(4);
    const [dataExport, setDataExport] = useState([]);


    useEffect(() => {
      const funReviewCount = async () => {
        if (searchTerm != '') {
          const getReviewCount = await getNumberOfQueryReview(searchTerm)
          setReviewCount(getReviewCount)
        } else {
          const getReviewCount = await fetchReviewCount()
          setReviewCount(getReviewCount)
        }
      }
      funReviewCount()
      const funAllReviews = async () => {
        let allReviews;
        if (searchTerm != '') {
          allReviews = await fetchQueryReviews((currentPage - 1) * usersPerPage, currentPage * usersPerPage, searchTerm)
          setReviews(allReviews)
        } else {
          allReviews = await fetchReviews((currentPage - 1) * usersPerPage, currentPage * usersPerPage)
          setReviews(allReviews)
        }
          const upList = await Promise.all(
            allReviews.map(async (item) => {
              const secondResponse = await getUserById(item.user_id)
              return {review: item, user: secondResponse}
            })
          )
          setDataExport(upList)
      }
      funAllReviews()
    },[])

    console.log(dataExport)
    
    const paginate = (pageNumber) => {
      window.location.href = "/admin/reviews?page=" + pageNumber + (url.searchParams.get("query") === null ? '' : "&query=" + url.searchParams.get("query"))
  }

  const handleQuery = (e) => {
    e.preventDefault()
    if (url.searchParams.get('query') === null) {
        url.searchParams.set('query', searchTerm);
        window.history.pushState({}, '', url);
    }
    window.location.href = "/admin/reviews?page=1&query=" + searchTerm
}

    const handleEditReview = (event) => {
      window.location.href = "/admin/reviews/edit/" + event.target.value
  }

  const handleArticlesInReview = (event) => {
    window.location.href = "/admin/reviews/articles/" + event.target.value
}
    const handleAddReview = () => {
      window.location.href = "/admin/reviews/add"
    }

    const handleBackToDashboard = () => {
      window.location.href = "/admin";
    };

    return (
        <>
        <div className="w-full px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between mb-6">
        <button
          onClick={handleBackToDashboard}
          className="bg-gray-200 hover:bg-gray-300 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center"
        >
          <ArrowLeft className="mr-2" size={20} />
          Retour
        </button>
        <h1 className="text-2xl font-bold text-center flex-grow">
          Admini'revue de presse
        </h1>
      </div>
      <form onSubmit={handleQuery} className="flex items-center mb-4">
      <div className="flex-grow mr-2">
        <input
          type="text"
          placeholder="Rechercher par thème ou présentation..."
          className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      <button
        type="submit"
        className="bg-green-500 hover:bg-green-700 text-white font-bold mr-2 py-2 px-4 rounded-md whitespace-nowrap"
      >
        Recherche
      </button>
      <button
        type="button"
        className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded-md whitespace-nowrap"
        onClick={handleAddReview}
      >
        Ajouter
      </button>
    </form>

    <div className="w-full overflow-x-auto shadow-md rounded-lg">
      <table className="w-full table-auto">
        <thead className="bg-gray-100">
          <tr>
            <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Thème</th>
            <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Présentation</th>
            <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Auteur</th>
            <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200">
          {dataExport.map((e, index) => (
            <tr key={index} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
              <td className="px-4 py-4 break-words">
                <div className="max-w-xs lg:max-w-sm xl:max-w-md">{e.review.theme}</div>
              </td>
              <td className="px-4 py-4 break-words">
                <div className="max-w-xs lg:max-w-sm xl:max-w-md">{e.review.presentation}</div>
              </td>
              <td className="px-4 py-4 break-words">
                <div className="max-w-xs lg:max-w-sm xl:max-w-md">{e.user.firstname + " " + e.user.lastname || "utilisateur inexistant"}</div>
              </td>
              <td className="px-4 py-4 whitespace-nowrap">
                <button className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mr-2 mb-2 sm:mb-0" value={e.review.id} onClick={handleArticlesInReview}> Voir les articles </button>
                <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-2 mb-2 sm:mb-0" value={e.review.id} onClick={handleEditReview}>
                  Éditer
                </button>
                <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded" onClick={() => {
                    if (window.confirm("Êtes-vous sûr de vouloir supprimer cet élément ?")) {
                        deleteReview(e.review.id).then(async () => {
                          alert("Revue supprimée !")
                        }).catch((err) => alert("Erreur lors de la suppression de la revue !"))
                        window.location.href = "/admin/reviews"
                      } else {
                        alert("Suppression annulée");
                      }
                }}>
                  Supprimer
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
    <Pagination
                usersPerPage={usersPerPage}
                totalUsers={reviewCount}
                paginate={paginate}
                currentPage={currentPage}
            />
  </div>
        </>
    )
}

function Pagination({ usersPerPage, totalUsers, paginate, currentPage }) {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalUsers / usersPerPage); i++) {
      pageNumbers.push(i);
  }

  return (
      <nav className="flex justify-center mt-4">
          <ul className="flex">
              {pageNumbers.map(number => (
                  <li key={number} className="mx-1">
                      <button
                          onClick={() => paginate(number)}
                          className={`px-3 py-2 rounded-md ${
                              currentPage === number
                                  ? 'bg-blue-500 text-white'
                                  : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
                          }`}
                      >
                          {number}
                      </button>
                  </li>
              ))}
          </ul>
      </nav>
  );
}