import authHeader from "./auth.header"
import constants from './constants.js'

const HOST_URL = constants.API_SCHEME + "://" + constants.API_HOST_NAME + ":" + constants.API_PORT;


/**
 * @param {{title, link, image?, country, publication_date, description}} favorite 
 * Every property in favorite is a string
 * @returns boolean
**/
export async function addFavorite(favorite) {
    const userId = JSON.parse(localStorage.getItem('user')).id
    if (!userId) return

    const response = await fetch(`${HOST_URL}/users/${userId}/favorites`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            ...authHeader()
        },
        body: JSON.stringify(favorite)
    })

    if (!response.ok) {
        console.log("request failed\n. response code, ", response.code)
        console.log("response payload, ", await response.json())
        return false
    } 
    
    // Extraire et retourner les données de la réponse
    const responseData = await response.json();
    return responseData;
}

export async function removeFavorite(favorite) {
    const userId = JSON.parse(localStorage.getItem('user')).id
    if (!userId) return
    const token = localStorage.getItem("token");
    const response = await fetch(`${HOST_URL}/favorites/remove`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            ...authHeader()
        },
        body: JSON.stringify({'user_id':userId,'link':favorite.link, 'token':token})
    })

    if (!response.ok) {
        console.log("request failed\n. response code, ", response.code)
        console.log("response payload, ", await response.json())
        return false
    }

    return true
}

/**
  * Returns a list of favorites.
  * @returns {[
  * favorite: {
    * title: string, 
    * link: string, 
    * image: string, 
    * country: string 
    * publication_date: string, 
    * description: string 
    * }
  * ]}
*/
export async function fetchFavorites() {
    const userId = JSON.parse(localStorage.getItem('user')).id
    if (!userId) return
    const response = await fetch(`${HOST_URL}/users/${userId}/favorites`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            ...authHeader()
        },
    })

    if (!response.ok) {
        console.log("request failed\n. response code, ", response.code)
        console.log("response payload, ", await response.json())
        return []
    }

    const jsonResponse = await response.json()
    const favorites = jsonResponse.favorites
    return favorites
}

/* Reviews */
export async function createReview(theme, presentation, selection, publishable_review) {
    const userId = JSON.parse(localStorage.getItem('user')).id
    if (!userId) return
    const articles = []
    for(let fav of selection){
        articles.push({
            favorite_id: fav.id,
            title: fav.title,
            link: fav.link,
            image: fav.image,
            country: fav.country,
            description: fav.description
        })
    }
    const response = await fetch(`${HOST_URL}/users/${userId}/reviews/articles`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            ...authHeader()
        },
        body: JSON.stringify({
           'theme': theme,
           'presentation': presentation,
           'image': '', // Image ???
           'visibility_id': 1, // est-ce que ça correspond à l'ordre d'apparition
           'numero': 1, // est-ce que ça correspond à l'ordre d'apparition 
           'articles': articles,
           'sharefriends': publishable_review ? 1 : 0
        })
    })

    if (!response.ok) {
        console.log("request failed\n. response code, ", response.code)
        console.log("response payload, ", await response.json())
        return false
    }
      
    return await response.json()
}

export async function deleteArticleInReview(reviewId, articleId) {
    const userAdmin = JSON.parse(localStorage.getItem('user')).admin
    if (!userAdmin) return

    const response = await fetch(`${HOST_URL}/reviews/${reviewId}/articles?id=${articleId}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            ...authHeader()
        },
        body: JSON.stringify({'reviewId':reviewId})
    })

    if (!response.ok) {
        console.log("request failed\n. response code, ", response.code)
        console.log("response payload, ", await response.json())
        return false
    }

    return true
}

export async function deleteReview(reviewId) {
    const userId = JSON.parse(localStorage.getItem('user')).id
    if (!userId) return
    const token = localStorage.getItem("token");
    const response = await fetch(`${HOST_URL}/users/${userId}/reviews/${reviewId}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            ...authHeader()
        },
        body: JSON.stringify({'token':token, 'userId':userId, 'reviewId':reviewId})
    })

    if (!response.ok) {
        console.log("request failed\n. response code, ", response.code)
        console.log("response payload, ", await response.json())
        return false
    }

    return true
}

export async function getReviewById(reviewId) { 
    const response = await fetch(`${HOST_URL}/reviews/${reviewId}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            ...authHeader()
        },
    })

    if (!response.ok) {
        console.log("request failed\n. response code, ", response.code)
        console.log("response payload, ", await response.json())
        return []
    }

    const jsonResponse = await response.json()
    return jsonResponse
    // const favorites = jsonResponse.favorites
    // return favorites
}

export async function getPublishableReviewByUserId(userId) { 
    const response = await fetch(`${HOST_URL}/users/${userId}/publishable-review`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            ...authHeader()
        },
    })

    if (!response.ok) {
        console.log("request failed\n. response code, ", response.code)
        console.log("response payload, ", await response.json())
        return []
    }

    const jsonResponse = await response.json()
    return jsonResponse
    // const favorites = jsonResponse.favorites
    // return favorites
}

export async function updateReview(review) {
    const userId = JSON.parse(localStorage.getItem('user')).id
    if (!userId) return
    const token = localStorage.getItem("token");
    const response = await fetch(`${HOST_URL}/reviews/${review.id}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            ...authHeader()
        },
        body: JSON.stringify({
            'theme': review.theme,
            'presentation': review.presentation, 
            'sharefriends': review.sharefriends,
        })
    })

    if (!response.ok) {
        console.log("request failed\n. response code, ", response.code)
        console.log("response payload, ", await response.json())
        return false
    }

    return true
}

export async function removeArticle(articleId) {
    const userId = JSON.parse(localStorage.getItem('user')).id
    if (!userId) return
    const token = localStorage.getItem("token");
    const response = await fetch(`${HOST_URL}/articles/${articleId}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            ...authHeader()
        }
    })

    if (!response.ok) {
        console.log("request failed\n. response code, ", response.code)
        console.log("response payload, ", await response.json())
        return false
    }

    return true
}

export async function addArticlesToReview(articles, reviewId) {
    const userId = JSON.parse(localStorage.getItem('user')).id
    if (!userId) return
    const token = localStorage.getItem("token");
    const response = await fetch(`${HOST_URL}/reviews/${reviewId}/articles`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            ...authHeader()
        },
        body: JSON.stringify(articles)
    })

    if (!response.ok) {
        console.log("request failed\n. response code, ", response.code)
        console.log("response payload, ", await response.json())
        return false
    }

    return true
}