import { useEffect, useState } from "react";
import { countries as allCountries } from "../../global/countriesAndSources"
import {ProfileHeader } from "./profileHeader"
import externalScripts  from "../../Helper/useExternalScripts";
import GoogleCSE from "./GoogleCSE";
import translation from "../../HTTP/translation";
import Map from "../Map/Map";
import { CountryBar } from "./countryBar";

export default function WhichCountriesSpeakAbout () {
    const [displayMap, setDisplayMap] = useState(false)
    if (sessionStorage.getItem('search_themes_country') !== null) {
        sessionStorage.removeItem('search_themes_country')
    }
    let country;
    if(JSON.parse(sessionStorage.getItem('search_country_country')) !== null) {
        country = JSON.parse(sessionStorage.getItem('search_country_country'))
    } else {
        const queryCountry = new URLSearchParams(window.location.search).get('country')
        country = queryCountry ? allCountries.find((c) => c.code === queryCountry) : null
        country = country
                || JSON.parse(sessionStorage.getItem("search_country"))
                || allCountries.find((c) => c.code === JSON.parse(localStorage.getItem('user'))?.country)
                || { name: "France", code: "fr", cse: "004899679196972058950:gvpguoasgrm" };
        sessionStorage.setItem('search_country_country',JSON.stringify(country))
    }
    
    const [selectedCountry] = useState(country)

    const selectNewCountry = async (country) => {
        const langStart = await translation.getLangFromCountry(selectedCountry.code)
        const langDest = await translation.getLangFromCountry(country.code)
        const trad = await translation.getTranslation(langStart, langDest, JSON.parse(sessionStorage.getItem('search_country')).name)
        sessionStorage.setItem('search_query', trad)
        window.location.href = `/${country.code}/which-countries-speak-about`
        sessionStorage.setItem("search_country_country", JSON.stringify(country))
    }

    const handleDisplayMap = () => {
		if(displayMap) {
			setDisplayMap(false)
			console.log(displayMap)
		} else {
			setDisplayMap(true)
			console.log(displayMap)
		}
	}

    return (
        <>
        <ProfileHeader 
                selectedCountry={selectedCountry} 
                setSelectedCountry = {(country) => selectNewCountry(country)}
            />
        <div className="flex">
            <div className="flex-1">
                <h1 className="ml-3">Quels pays en parlent ?</h1>
                <Map 
                    selectedCountry={selectedCountry} 
                    setSelectedCountry = {(country) => selectNewCountry(country)}
                    displayMap = {displayMap}
                    setDisplayMap = {(hide) => setDisplayMap(hide)}
                />
                <div style={{height: '50vh'}}>
                    <GoogleCSE 
                        selectedCountry = {country}
                    />
                </div>
            </div>
            <div id="pays_large" className="w-60 bg-neutral-50">
            <CountryBar 
                setSelectedCountry = {(country) => selectNewCountry(country)}
                displayMap = {() => handleDisplayMap()}
            />
            </div>
        </div>
        </>
    )
}