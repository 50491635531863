import { useEffect, useState, useMemo } from "react";
import { useParams } from "react-router-dom";
import { getAllPublicUsers } from "../../HTTP/user";
import { deleteArticleInReview, getReviewById } from "../../HTTP/articles";


export default function ArticlesInReviewAdmin() {
    const { id } = useParams();
    const [users, setUsers] = useState([])
    const [formData, setFormData] = useState({
        id: 0,
        theme: '',
        presentation: '',
        sharefriends: false,
        articles: []
    });
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        const fetchArticle = async () => {
            try {
                // Remplacez getArticleById par votre fonction réelle pour récupérer un article
                const review = await getReviewById(id);
                setFormData(review);
            } catch (error) {
                console.error("Erreur lors de la récupération de l'article:", error);
            }
        };
        if (id) {
            fetchArticle();
        }
    }, [id]);

    const getUserMemo = useMemo(() => {
        return (user_id) => {
            if (users.length === 0) {
                return "oui"
            }
            const u = users.find((u) => u.id === user_id)
            if (u) {
                const name = u.firstname + " " + u.lastname
                return name
            } else {
                return "utilisateur inexistant"
            }
        }
    }, [users]);

    const filteredFavs = useMemo(() => {
        return formData.articles.filter(article => {
            const searchLower = searchTerm.toLowerCase();
            return (
                (article.title?.toLowerCase().includes(searchLower) ?? false) ||
                (getUserMemo(article.user_id)?.toLowerCase().includes(searchLower) ?? false) ||
                (article.description?.toLowerCase().includes(searchLower) ?? false)
            );
        });
    }, [formData.articles, searchTerm, getUserMemo]);

    return (
        <div className="w-full px-4 sm:px-6 lg:px-8">
            <h1 className="text-2xl font-bold mb-6 text-center">
                Admini'favoris
            </h1>
            <div className="flex items-center mb-4">
                <div className="flex-grow mr-2">
                    <input
                        type="text"
                        placeholder="Rechercher par titre, auteur ou description..."
                        className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                </div>
                {/* <button
                    className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded-md whitespace-nowrap"
                >
                    Ajouter
                </button> */}
            </div>
            <div className="w-full overflow-x-auto shadow-md rounded-lg">
                <table className="w-full table-auto">
                    <thead className="bg-gray-100">
                        <tr>
                            <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Titre</th>
                            <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Lien</th>
                            <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Description</th>
                            <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
                        </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200">
                        {filteredFavs.map((article, index) => (
                            <tr key={index} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                                <td className="px-4 py-4 break-words">
                                    <div className="max-w-xs lg:max-w-sm xl:max-w-md">{article.title}</div>
                                </td>
                                {/* <td className="px-4 py-4 break-words">
                                    <div className="max-w-xs lg:max-w-sm xl:max-w-md">{getUserMemo(article.user_id) || "utilisateur inexistant"}</div>
                                </td> */}
                                <td className="px-4 py-4 break-words">
                                    <div className="max-w-xs lg:max-w-sm xl:max-w-md">
                                        <a href={article.link} className="text-blue-600 hover:text-blue-800" target="_blank" rel="noopener noreferrer">
                                            Lien
                                        </a>
                                    </div>
                                </td>
                                <td className="px-4 py-4 break-words">
                                    <div className="max-w-xs lg:max-w-sm xl:max-w-md">{article.description}</div>
                                </td>
                                <td className="px-4 py-4 whitespace-nowrap">
                                    <button 
                                        className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                                        onClick={() => {
                                            if (window.confirm("Êtes-vous sûr de vouloir supprimer cet élément ?")) {
                                                deleteArticleInReview(id, article.id).then(() => alert("Article supprimé !")).catch((err) => alert("Erreur lors de la suppression de l'article", err))
                                                window.location.href = "/admin/reviews/articles/" + id
                                            } else {
                                                alert("Suppression annulée");
                                            }
                                        }}
                                    >
                                        Supprimer
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}