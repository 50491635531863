import React from "react"
import "./Admin.css"

export default function Admin() {
    const localUser = JSON.parse(localStorage.getItem("user"))
    if (!localUser.admin) {
        window.location.href = '/'
    }

    const handleUserAdmin = () => {
        window.location.href = "/admin/users"
    }

    const handleReviewAdmin = () => {
        window.location.href = "/admin/reviews"
    }

    const handleFavoritesAdmin = () => {
        window.location.href = "/admin/favorites"
    }

    return (
        <>
        <h1 className="mt-2 ml-2">
            Admin
        </h1>
        <div className="dashboard">
            <button
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                type="button"
                onClick={handleUserAdmin}
            >
                Utilisateurs
            </button>
            <button
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                type="button"
                onClick={handleReviewAdmin}
            >
                Revues de presse
            </button>
            <button
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                type="button"
                onClick={handleFavoritesAdmin}
            >
                Favoris
            </button>
        </div>
        </>
    )
}